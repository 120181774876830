<div
  *ngxPermissionsOnly="requiredPermission"
  [class]="selectable ? 'cursor-pointer' : ''"
  class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex"
>
  <div class="p-4">
    <div class="flex align-items-center justify-content-between gap-3">
      <div class="flex align-items-center gap-1">
        @if (icon) {
          <span
            [class]="
              'inline-flex border-circle align-items-center justify-content-center ' +
              ' bg-' +
              color +
              '-100 ' +
              ' mr-3'
            "
            style="width: 38px; height: 38px"
          >
            <i [class]="'pi ' + icon + ' text-xl text-' + color + '-600'"></i>
          </span>
        }
        <span class="text-900 font-medium text-2xl">{{ title }}</span>
      </div>
      @if (showAmount) {
        @if (!isLoading) {
          <div class="line-height-4 text-4xl">{{ value ?? 0 }}</div>
        }
        @if (isLoading) {
          <p-skeleton width="4rem" height="2rem" styleClass="my-3"></p-skeleton>
        }
      }
      <ng-content select="[header-actions]"></ng-content>
    </div>
    <!-- <div class="text-900 my-3 text-xl font-medium"></div> -->
    @if (description) {
      <p class="mt-0 mb-3 text-700 line-height-3">
        {{ description }}
      </p>
    }
    <ng-content></ng-content>
  </div>
  @if (showSettingsLink || showReportsLink) {
    <div class="px-4 py-3 surface-100 text-right flex flex-row gap-3 justify-content-end">
      <!-- <i class="pi pi-list"></i> -->
      @if (showSettingsLink) {
        <a
          *ngxPermissionsOnly="requiredAdminPermission"
          [routerLink]="settingsRoute"
          class="p-button p-button-secondary flex flex-row gap-2"
          >{{ 'general.actions.settings' | translate }} <i class="pi pi-cog"></i>
        </a>
      }
      @if (showReportsLink) {
        <a [routerLink]="listRoute" [class]="'p-button p-button-' + color + ' flex flex-row gap-2'"
          >{{ 'general.actions.reports' | translate }} <i class="pi pi-angle-right"></i>
        </a>
      }
      <!-- <app-button [action]="action2" [commandData]="actionData"></app-button>
      <app-button [action]="action" [commandData]="actionData"></app-button> -->
    </div>
  }
</div>
