import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetFieldDto, BadgeColor, BadgeIcon, ModuleIcons, isNullObj } from '@shared/classes';
import { PathResolverService } from '@shared/services/path-resolver.service';
import * as d3 from 'd3';
import { OrgChart } from 'd3-org-chart';
import PieChart from './PieChart';
@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss'],
})
export class OrgChartComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('chartContainer') chartContainer: ElementRef;
  chart;
  enumColors;
  index = 0;
  compact = 0;
  actNdCent = 0;
  private _chartData = [];
  get chartData() {
    return this._chartData;
  }
  @Input() set chartData(chartData) {
    this._chartData = chartData;
    if (
      this.getPieChartData &&
      this.chartContainer &&
      (!isNullObj(this.chartData) || this.rootNode || this.parentNode)
    ) {
      this.renderChart(this.getPieChartData);
    } else if (isNullObj(this.chartData) && !this.rootNode && !this.parentNode) {
      // d3?.select('svg')?.selectAll('*')?.remove();
      d3?.select('#chartContainer')?.html(null);
    }
  }
  @Output() onFilterClick: EventEmitter<any> = new EventEmitter();
  @Input() rootNode: AssetFieldDto;
  @Input() parentNode: AssetFieldDto;
  @Input() moduleKeyword: any;
  showOtherActions: boolean = false;
  @Input() showStatistics: boolean = true;
  renderedNodeList: { wrapper: any; d: any }[] = [];
  private _getPieChartData: (input: any) => { data: { key: string; color: string; value: number }[]; color: string };
  @Input() set getPieChartData(
    getPieChartData: (input: any) => { data: { key: string; color: string; value: number }[]; color: string }
  ) {
    this._getPieChartData = getPieChartData;
    if (!isNullObj(this.chartData) && this.chartContainer && this.getPieChartData) {
      // this.renderChart(this.getPieChartData);
      this.changePieChartRender();
    }
  }
  get getPieChartData() {
    return this._getPieChartData;
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pathResolverService: PathResolverService
  ) { }
  ngOnDestroy(): void {
    window.removeEventListener('buttonClickViewType', this.handleButtonClickViewType);
    window.removeEventListener('buttonClickView', this.handleButtonClickView);
    window.removeEventListener('buttonClickEdit', this.handleButtonClick);
    window.removeEventListener('buttonClickAddChild', this.handleButtonClickAddChild);
    d3.select('#chartContainer').html(null);
  }
  onFilterButtonClick(event) {
    this.onFilterClick.emit(event);
  }
  onChangeViewMode(mode: { event?: any; value?: any }) {
    this.renderChart(this.getPieChartData);
  }
  formatDataForChart(data: AssetFieldDto[]) {
    let retData: any[];
    retData = [
      ...data.map((x) => {
        return {
          ...x,
          name: x.name,
          id: x.code,
          imageUrl: '',
          positionName: x.description,
          _centered: null,
          _centeredWithDescendants: null,
          _directSubordinates: 0,
          _expanded: false,
          _totalSubordinates: 0,
          parentId: this.parentNode?.code ?? (x.parent || 'root'),
        };
      }),
      {
        ...this.rootNode,
        name: this.rootNode?.name || this.moduleKeyword || '',
        id: 'root',
        imageUrl: '',
        positionName: this.rootNode?.description,
        _centered: null,
        _centeredWithDescendants: null,
        _directSubordinates: 0,
        _expanded: false,
        _totalSubordinates: 0,
        parentId: null,
        risks: [],
        issues: [],
        controls: [],
        entityTypeInfo: {
          ...this.rootNode?.entityTypeInfo,
          icon: this.rootNode?.entityTypeInfo?.icon || ModuleIcons[(this.moduleKeyword as string).toUpperCase()],
        },
      },
    ];
    if (this.parentNode?.code) {
      retData.push({
        ...this.parentNode,
        name: this.parentNode?.name,
        id: this.parentNode?.code,
        imageUrl: '',
        positionName: this.parentNode?.description,
        _centered: null,
        _centeredWithDescendants: null,
        _directSubordinates: 0,
        _expanded: false,
        _totalSubordinates: 0,
        parentId: 'root',
      });
    }
    return retData;
  }
  logData(string) { }
  private handleButtonClick = (event: CustomEvent) => {
    const nodeId = event?.detail?.nodeId;

    // Perform any desired actions here based on the node ID
    this.editNode(nodeId);
  };
  private handleButtonClickViewType = (event: CustomEvent) => {
    const nodeId = event?.detail?.nodeId;

    // Perform any desired actions here based on the node ID
    this.viewNodeType(nodeId);
  };
  private handleButtonClickView = (event: CustomEvent) => {
    const nodeId = event?.detail?.nodeId;

    // Perform any desired actions here based on the node ID
    this.viewNode(nodeId);
  };
  private handleButtonClickAddChild = (event: CustomEvent) => {
    const nodeId = event?.detail?.nodeId;

    // Perform any desired actions here based on the node ID
    this.addChild(nodeId, this.rootNode?.code);
  };
  viewNodeType(code) {
    const url = `entity/entity-types/view/${code}`;
    window.open(this.router.serializeUrl(this.router.createUrlTree([url])), '_blank');
  }

  viewNode(code) {
    const url = `entity/${this.moduleKeyword?.toLowerCase()}/view/${code}`;
    window.open(this.router.serializeUrl(this.router.createUrlTree([url])), '_blank');
  }

  editNode(code) {
    const url = `entity/${this.moduleKeyword?.toLowerCase()}/edit/${code}`;
    window.open(this.router.serializeUrl(this.router.createUrlTree([url])), '_blank');
  }

  addChild(code, orgCode) {
    const url = `entity/${this.moduleKeyword?.toLowerCase()}/create${orgCode ? `/${orgCode}` : ''}/${code}`;
    window.open(this.router.serializeUrl(this.router.createUrlTree([url])), '_blank');
  }
  moduleIcons = ModuleIcons;

  ngOnInit() {
    window.addEventListener('buttonClickViewType', this.handleButtonClickViewType);
    window.addEventListener('buttonClickView', this.handleButtonClickView);
    window.addEventListener('buttonClickEdit', this.handleButtonClick);
    this.handleButtonClickAddChild = this.handleButtonClickAddChild.bind(this);
    window.addEventListener('buttonClickAddChild', this.handleButtonClickAddChild);
  }

  ngAfterViewInit() {
    if (!this.chart) {
      if (this.getPieChartData && !isNullObj(this.chartData) && this.chartContainer) {
        this.chart = new OrgChart();
        this.renderChart(this.getPieChartData);
      }
    }
    this.updateChart();
  }

  ngOnChanges() {
    this.updateChart();
  }
  updateChart() {
    // if (!this.chartData) {
    //     return;
    // }
    // if (!this.chart) {
    //     return;
    // }
    // this.chart
    //     .container(this.chartContainer.nativeElement)
    //     .data(this.formatDataForChart(this.chartData))
    //     .nodeWidth(d => 200)
    //     .nodeHeight(d => 120)
    //     .render();
  }
  swap() {
    // this.index = (this.index + 1) % 4;
    this.chart
      .layout(['right', 'bottom', 'left', 'top'][this.index++ % 4])
      .render()
      .fit();
  }
  onCompact() {
    this.chart
      .compact(!!(this.compact++ % 2))
      .render()
      .fit();
  }
  toggleCenterActive() {
    this.chart.setActiveNodeCentered(!!(this.actNdCent++ % 2)).render();
  }
  downloadPdf() {
    this.chart.exportImg({
      save: false,
      onLoad: (base64) => {
        import('jspdf').then((jsPDF: any) => {
          var pdf = new jsPDF.default();
          var img = new Image();
          img.src = base64;
          img.onload = function () {
            pdf.addImage(img, 'JPEG', 5, 5, 595 / 3, ((img.height / img.width) * 595) / 3);
            pdf.save('chart.pdf');
          };
        });
      },
    });
  }
  renderRiskChart(d) {
    const totalCount = d.data.risks.length;

    const enumValues = ['DRAFT', 'SUBMITTED', 'REJECTED', 'RETIRED', 'APPROVED'];

    const result = enumValues.map((enumValue) => {
      const count = d?.data?.risks?.filter((item) => item.status === enumValue)?.length;
      const percentage = !totalCount ? 0 : (count / totalCount) * 100;
      const colors = BadgeColor;
      return { key: enumValue.toLowerCase(), color: 'var(--' + colors[enumValue] + '-400)', value: percentage || 0 };
    });
    return { data: totalCount ? result : [], color: 'yellow' };
  }
  renderIssuesChart(d) {
    const totalCount = d.data.issues.length;

    const enumValues = ['CRITICAL', 'HIGH', 'MODERATE', 'LOW', 'INFO'];
    const colors = BadgeColor;

    const result = enumValues.map((enumValue) => {
      const count = d?.data?.issues?.filter((item) => item.criticality === enumValue)?.length;
      const percentage = !totalCount ? 0 : (count / totalCount) * 100;
      return { key: enumValue.toLowerCase(), color: 'var(--' + colors[enumValue] + '-400)', value: percentage || 0 };
    });
    const highestCriticality = result
      .sort((a, b) => enumValues.indexOf(a.key.toUpperCase()) - enumValues.indexOf(b.key.toUpperCase()))
      .find((item) => item.value > 0);
    const highestCriticalityColor = highestCriticality
      ? 'var(--' + colors[highestCriticality.key.toUpperCase()] + '-400)'
      : '';
    return { data: totalCount ? result : [], color: highestCriticalityColor };
  }
  renderControlsChart(d) {
    const totalCount = d.data.controls.length;

    const enumValues = ['COMPLIANT', 'NON_COMPLIANT'];

    const colors = BadgeColor;
    const result = enumValues.map((enumValue) => {
      const count = d?.data?.controls?.filter((item) => item.controlComplianceStatus === enumValue)?.length;
      const percentage = !totalCount ? 0 : (count / totalCount) * 100;
      return { key: enumValue.toLowerCase(), color: 'var(--' + colors[enumValue] + '-400)', value: percentage || 0 };
    });
    let color;
    if (result.find((item) => item.key === 'non_compliant' && item.value > 0)) {
      color = 'var(--' + colors.NON_COMPLIANT + '-400)'; // Get the color for 'INCOMPLIANT'
    } else {
      color = 'var(--' + colors.COMPLIANT + '-400)'; // Get the color for 'COMPLIANT'
    }
    return { data: totalCount ? result : [], color: color };
  }
  changePieChartRender() {
    this.renderedNodeList.forEach((node) => {
      const d = node.d;
      const pieChartWrapperNode = node.wrapper;
      // General pie chart invocation code
      const pieChartData = this.getPieChartData(d);
      new PieChart()
        .data(pieChartData.data)
        .container(pieChartWrapperNode)
        .svgHeight(200)
        .svgWidth(320)
        .marginTop(40)
        .image(d?.data?.entityTypeInfo?.icon)
        .imageBg(pieChartData.color)
        .backCircleColor('var(--surface-900)')
        .render();
    });
  }
  renderChart(
    getPieChartData: (input: any) => { data: { key: string; color: string; value: number }[]; color: string }
  ) {
    // d3.select('svg').selectAll('*').remove();
    d3.select('#chartContainer').html(null);
    let dataFlattened = this.formatDataForChart(this.chartData);

    const _this = this;
    _this.renderedNodeList = [];
    this.chart = new OrgChart()
      .container(this.chartContainer.nativeElement)
      .svgHeight(window.innerHeight - 10)
      .data(dataFlattened)
      .nodeHeight((d) => 200)
      .nodeWidth((d) => {
        if (d.depth == 0) return 475;
        return 475;
      })
      .childrenMargin((d) => 90)
      .compactMarginBetween((d) => 65)
      .compactMarginPair((d) => 100)
      .neightbourMargin((a, b) => 50)
      .siblingsMargin((d) => 100)
      .buttonContent(({ node, state }) => {
        return `<div style="color:var(--surface-900);border-radius:var(--border-radius);padding:3px;font-size:10px;margin:auto auto;background-color:var(--surface-200);border: 1px solid var(--surface-900)"> <span style="font-size:9px">${node.children ? `<i class="fas fa-angle-up"></i>` : `<i class="fas fa-angle-down"></i>`
          }</span> ${node.data._directSubordinates}  </div>`;
      })
      .linkUpdate(function (d, i, arr) {
        d3.select(this)
          .attr('stroke', (d) => (d.data._upToTheRootHighlighted ? '#14760D' : 'var(--surface-900)'))
          .attr('stroke-width', (d) => (d.data._upToTheRootHighlighted ? 15 : 1));

        if (d.data._upToTheRootHighlighted) {
          d3.select(this).raise();
        }
      })
      .nodeContent(function (d, i, arr, state) {
        const svgStr = `<svg  style="background-color:none; width:150px; height:75px;"> <path d="M 0,15 L15,0 L135,0 L150,15 L150,60 L135,75 L15,75 L0,60" fill="#2599DD" stroke="#2599DD"/> </svg>`;
        const badgeColors = BadgeColor;
        const badgeIcons = BadgeIcon;
        return `

                            <div style="background-color:var(--surface-200);sans-serif; position:absolute;margin-top:-1px; margin-left:-1px;    width:${d.width
          }px;height:${d.height}px;border-radius:var(--border-radius);border: 2px solid var(--surface-900)">

                               <div class="pie-chart-wrapper" style="margin-left:-10px;margin-top:25px;width:320px;height:300px;${_this.showStatistics ? '' : ' display:none; '}"></div>

                              <div class="text-section-wrapper" style="color:var(--surface-900);${d?.data?.id != 'root' && _this.showStatistics ? ' position:absolute; ' : '     text-align: center; display: flex;align-items: center;justify-content: center;'}">
                                <div style="display:flex; flex-direction:column; justify-content:space-between;width:100%; ${d?.data?.id != 'root' && _this.showStatistics ? ' height:100%; ' : ''}">
                                <div>
                                <div style="${d?.data?.id != 'root' && _this.showStatistics ? 'font-size:22px;' : 'font-size:33px;'} color:var(--surface-900);margin-top:12px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: 100%;">

                        <div onclick="window.dispatchEvent(new CustomEvent('buttonClickView', { detail: {nodeId:'${d?.data?.code}',target:this} }))"
                        class=""
                        style="    text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        padding: 5px;
                        width: 100%;"
                        title="${d?.data?.name}"
                        >
                        ${d?.data?.id != 'root' ? `<i class="${badgeIcons[d?.data?.recordStatus]} text-${badgeColors[d?.data?.recordStatus]}-400 " style="${_this.showStatistics ? 'font-size: 18px; ' : 'font-size: 28px; '}"></i>` : ''}
                            ${d?.data?.code != 'root' && d?.data?.code ? `<strong>${d?.data?.code || ''}</strong> :` : ''} ${d.data.name}
                        </div>

                    </div>
                    <div onclick="window.dispatchEvent(new CustomEvent('buttonClickViewType', { detail: {nodeId:'${d?.data?.entityTypeInfo?.code}',target:this} }))"
                    class=""
                    style="margin-top: 0.5rem;     text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 100%;"
                    title="${d?.data?.entityTypeInfo?.name || ''}"
                    >
                        ${d?.data?.id != 'root' && _this.showStatistics && d.data?.entityTypeInfo?.code ? `<i class="${ModuleIcons.EntityType} text${badgeColors[d?.data?.entityTypeInfo?.recordStatus]}-400 " style="font-size: 12px;"></i>` : ''}
                        ${d?.data?.id != 'root' && _this.showStatistics && d.data?.entityTypeInfo?.code ? (d.data?.entityTypeInfo?.code || '') + ':' : ''} ${d?.data?.entityTypeInfo?.name || ''}
                    </div>
                    ${_this.showStatistics
            ? `${d?.data?.id != 'root'
              ? `
                    <div class="" style="margin-top: 0.5rem;font-size:14px;">
                        <i class="${ModuleIcons.RiskItem}" style="font-size: 12px;"></i>
                        Risks: ${d.data?.risks?.length || '0'}
                    </div>
                    `
              : ''
            }
                    ${d?.data?.id != 'root'
              ? `
                    <div class="" style="margin-top: 0.5rem;font-size:14px;">
                        <i class="${ModuleIcons.Issues}" style="font-size: 12px;"></i>
                        Issues: ${d.data?.issues?.length || '0'}
                    </div>
                    `
              : ''
            }
                    ${d?.data?.id != 'root'
              ? `
                    <div class="" style="margin-top: 0.5rem; font-size:14px;">
                        <i class="${ModuleIcons.Control}" style="font-size: 12px;"></i>
                        Controls: ${d?.data?.controls?.length ? d?.data?.controls?.filter((item) => item.controlComplianceStatus != null)?.length : '0'}
                    </div>
                    `
              : ''
            }`
            : ''
          }

                                </div>
                                <div style="${!_this.showStatistics ? 'position: absolute; bottom: 5px; left: 5px; ' : ''}">
                                ${d?.data?.id != 'root'
            ? `<button class="p-button  p-button-primary" onclick="window.dispatchEvent(new CustomEvent('buttonClickEdit', { detail: {nodeId:'${d.data.id}',target:this} }))"><i class="pi pi-pencil"></i> </button>
                                <button class="p-button  p-button-primary" onclick="window.dispatchEvent(new CustomEvent('buttonClickAddChild', { detail: {nodeId:'${d.data.id}',target:this} }))"><i class="pi pi-plus"></i> </button>`
            : ''
          }
                                </div>
                                </div>

                              </div>

                            </div>

    `;
      })
      .nodeUpdate(function (d, i, arr) {
        if (d?.data?.id != 'root' && _this.showStatistics) {
          d3.select(this)
            .select('.node-rect')
            .attr('stroke', (d) => (d.data._highlighted || d.data._upToTheRootHighlighted ? '#14760D' : 'none'))
            .attr('stroke-width', d.data._highlighted || d.data._upToTheRootHighlighted ? 40 : 1);

          const pieChartWrapperNode = d3.select(this).select('.pie-chart-wrapper').node();
          // General pie chart invocation code
          const pieChartData = _this.getPieChartData(d);
          new PieChart()
            .data(pieChartData.data)
            .container(pieChartWrapperNode)
            .svgHeight(200)
            .svgWidth(320)
            .marginTop(40)
            .image(d?.data?.entityTypeInfo?.icon)
            .imageBg(pieChartData.color)
            .backCircleColor('var(--surface-900)')
            .render();
          _this.renderedNodeList.push({ wrapper: pieChartWrapperNode, d: d });
        }
        // // const tooltip = d3.select('.tooltip');
        // d3.select(this)
        // .on('mouseenter.tooltip', function(event, d) {

        //     // Show the tooltip
        //     // tooltip.style('display', 'block');

        //     // // Set the tooltip content
        //     // tooltip.text(d?.data?.name);

        //     // // Position the tooltip relative to the mouse cursor
        //     // tooltip.style('left', event.pageX + 'px');
        //     // tooltip.style('top', event.pageY + 'px');
        // })
        // .on('mouseleave.tooltip', function(d) {

        //     // Hide the tooltip
        //     // tooltip.style('display', 'none');
        // });
      })
      .render();
  }
}
